import { fetchApi } from "../../utils/api";
export async function getHomeSlide(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/slide_home/get-list-slide-home`, 'get', payload);
        if (response.code !== 200) {
            return {
                data: {},
            };
        }
        return response.data;
    } catch (error) {
        return {
            rows: [],
        };
    }
}


export async function getHomeService(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/service/get-list-service?limit=12`, 'get', payload);
        if (response.code !== 200) {
            return {
                data: {},
            };
        }
        return response.data;
    } catch (error) {
        return {
            rows: [],
        };
    }
}


export async function getHomeProject(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/project/get-list-project-feature?sign=${"vi"}`, 'get', payload);
        if (response.code !== 200) {
            return {
                data: {},
            };
        }
        return response.data;
    } catch (error) {
        return {
            rows: [],
        };
    }
}

export async function getHomeImageCompany(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/image-office/get-list-image-office`, 'get', payload);
        if (response.code !== 200) {
            return {
                data: {},
            };
        }
        return response.data;
    } catch (error) {
        return {
            rows: [],
        };
    }
}